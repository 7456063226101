import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'
import Logo from '../../Images/Logo.png'

// import { useLocation } from "react-router-dom";

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b></b> Superior Chimney Sweep</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1> Superior Chimney Sweep</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Superior Chimney Sweep Appointment Online </a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faq</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                            Chimney Cap Repair 
                        </a>
                        <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                            Chimney Cleaning 
                        </a>
                        <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                            Chimney Construction 
                        </a>
                        <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                            Chimney Crown Repair 
                        </a>
                        <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                            Chimney Flue Installation 
                        </a>
                        <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                            Chimney Flue Repair 
                        </a>
                        <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                            Chimney Inspection 
                        </a>
                        <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                            Chimney Installation 
                        </a>
                        <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                            Chimney Insulation 
                        </a>
                        <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                            Chimney Cracks Repair 
                        </a>
                        <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                            Chimney Firebox Paint 
                        </a>
                        <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                            Chimney Firebox Cleaining 
                        </a>
                        <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                            Chimney Firebox Inspection 
                        </a>
                        <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                            Chimney Best Company 
                        </a>
                        <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                            Firebox Inspection 
                        </a>
                        <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                            Firebox Sweeping 
                        </a>
                        <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                            Fireplace Inspection 
                        </a>
                        <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                            Firepalce Repair 
                        </a>
                        <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                            Chimney Liner Repair 
                        </a>
                        <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                            Chimney Maintenance 
                        </a>
                        <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                            Chimney Pointing 
                        </a>
                        <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                            Chimney Rain Cap Installation 
                        </a>
                        <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                            Chimney Repair 
                        </a>
                        <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                            Chimney Restoration 
                        </a>
                        <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                            Chimney Flashing Repair 
                        </a>
                        <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                            Chimney Liner Installation 
                        </a>
                        <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                            Chimney Damper Repair 
                        </a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            lifornia
                        </a>
                        <a target='_blank' href="tel:951-596-5412">
                            951-596-5412
                        </a>
                        <a target='_blank' href="mailto:info@himneysweep.org">
                            info@himneysweep.org
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023  Superior Chimney Sweep. All rights reserved.</h1>
            </div>
        </div>
    )
}
